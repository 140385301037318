import IconCreditCardSim from "@/components/svg/credit-card-sim";

import GiftCardBoxes from "./GiftCardBoxes";
import GiftCardDetails from "./GiftCardDetails";
import CreditLogo from "./GiftCardLogo";

type GiftCardProps = {
    fullName: string;
    cardNumber: string;
    cvv: string | null;
    year: string;
    month: string;
};

const GiftCard: React.FC<GiftCardProps> = ({ fullName, cardNumber, cvv, year, month }) => {
    return (
        <div className="relative aspect-[16/9.5] w-full max-w-[420px] overflow-hidden rounded-[15px] border border-secondary-200 shadow-main @container min-[420px]:rounded-[20px]">
            <GiftCardBoxes rgb="hsl(192,97%,56%)" />
            <div className="relative z-30 flex h-full flex-col items-center justify-between p-[6%] @[320px]:p-[7%]">
                <div className="flex w-full items-center justify-between">
                    <CreditLogo logo={`/images/banks/logo.svg`} name="پردازش کاران تجارت یاشار" />
                    <IconCreditCardSim className="h-[22px] @[320px]:h-[28px]" />
                </div>
                <GiftCardDetails
                    cvv={cvv}
                    cardNumber={cardNumber || ""}
                    fullName={fullName}
                    year={year}
                    month={month}
                />
            </div>
        </div>
    );
};

export default GiftCard;
