import React, { useMemo } from "react";

type GiftCardDetailsProps = {
    fullName: string;
    cardNumber: string;
    cvv: string | null;
    year: string;
    month: string;
};

const GiftCardDetails: React.FC<GiftCardDetailsProps> = ({ cvv, cardNumber, fullName, year, month }) => {
    const renderCardNumber = useMemo(() => {
        const cardNumberGroups: React.ReactNode[][] = [[], [], [], []];

        const splicedCardNumber = cardNumber
            ?.replaceAll(" ", "")
            ?.split("")
            ?.map((digit, i) => (
                <span className="text-text-main" key={i}>
                    {digit}
                </span>
            ));

        Array.from({ length: 16 - (splicedCardNumber?.length || 0) })
            .fill("")
            .forEach(() => {
                splicedCardNumber?.push(
                    <span className="px-[2px] text-text-paragraph dark:text-[rgb(230,230,230)]">_</span>,
                );
            });

        splicedCardNumber?.forEach((digit, index) => {
            const groupIndex = Math.ceil((index + 1) / 4) - 1;

            cardNumberGroups[groupIndex]?.push(<span className="text-white">{digit}</span>);
        });

        return cardNumberGroups.map((group, index) => (
            <div key={index} className="text-left" dir="ltr">
                {group.map((item, i) => (
                    <React.Fragment key={i}>{item}</React.Fragment>
                ))}
            </div>
        ));
    }, [cardNumber]);

    const renderCvv = useMemo(() => {
        return cvv ? cvv : "0000";
    }, [cvv]);

    return (
        <div className={`w-full space-y-2 @[320px]:space-y-3`}>
            <div className="flex items-center justify-start text-[1rem] text-text-main @[320px]:text-[1.14rem]">
                {fullName}
            </div>
            <div
                className={`flex flex-row-reverse items-center justify-between text-[1.28rem] leading-6 @[320px]:text-[1.42rem] @[380px]:text-[1.71rem]`}
            >
                {renderCardNumber}
            </div>

            <div className="flex w-full justify-between">
                <div className="rtl flex w-full items-center justify-start text-[1rem] font-semibold text-text-main @[320px]:text-[1.14rem]">
                    <span>
                        {year}/{month}
                    </span>
                </div>
                <div className="rtl w-full whitespace-nowrap text-left text-[0.85rem] font-semibold text-text-main @[320px]:text-[1rem] @[380px]:text-[1.28rem]">
                    رمز روی کارت (cvv): {renderCvv}
                </div>
            </div>
        </div>
    );
};

export default GiftCardDetails;
