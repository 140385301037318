import IconCartShopping from "@/components/svg/cart-shopping";
import IconCartShoppingStroke from "@/components/svg/cart-shopping-stroke";
import IconChartLineSolid from "@/components/svg/chart-line-solid";
import IconCircleUser from "@/components/svg/circle-user";
import IconCircleUserStroke from "@/components/svg/circle-user-stroke";
import IconCoin from "@/components/svg/coin";
import IconCoinStroke from "@/components/svg/coin-stroke";
import IconHandshakeSimple from "@/components/svg/handshake-simple";
import IconHandshakeSimpleStroke from "@/components/svg/handshake-simple-stroke";
import IconHeadset from "@/components/svg/headset";
import IconHeadsetStroke from "@/components/svg/headset-stroke";
import IconHouse from "@/components/svg/house";
import IconHouseStroke from "@/components/svg/house-stroke";
import IconMemoCircleCheck from "@/components/svg/memo-circle-check";
import IconMemoCircleCheckStroke from "@/components/svg/memo-circle-check-stroke";
import IconMoneyCheckDollar from "@/components/svg/money-check-dollar";
import IconMoneyCheckDollarStroke from "@/components/svg/money-check-dollar-stroke";
import IconWallet from "@/components/svg/wallet";
import IconWalletStroke from "@/components/svg/wallet-stroke";

export const sidebarItems = [
    {
        icon: IconHouse,
        iconDisabled: IconHouseStroke,
        text: "داشبورد",
        link: ["/panel"],
    },
    {
        icon: IconCircleUser,
        iconDisabled: IconCircleUserStroke,
        text: "حساب کاربری من",
        link: ["#profile"],
        submenu: [
            {
                text: "احراز هویت",
                link: "/panel/authentication",
            },
            {
                text: "کارت‌های بانکی",
                link: "/panel/credit",
            },
            {
                text: "تنظیمات حساب کاربری",
                link: "/panel/profile",
            },
            {
                text: "شخصی سازی",
                link: "/panel/appearance",
            },
        ],
    },
    {
        icon: IconCartShopping,
        iconDisabled: IconCartShoppingStroke,
        text: "ثبت سفارش",
        link: ["/panel/order", "/panel/order/new"],
    },
    {
        icon: IconCoin,
        iconDisabled: IconCoinStroke,
        text: "نقد درآمد ارزی",
        link: ["/panel/cash-income"],
    },
    {
        icon: IconMemoCircleCheck,
        iconDisabled: IconMemoCircleCheckStroke,
        text: "لیست سفارش‌ها",
        link: ["/panel/order/list"],
    },
    {
        icon: IconMoneyCheckDollar,
        iconDisabled: IconMoneyCheckDollarStroke,
        text: "لیست نقدها",
        link: ["/panel/cash-income/list"],
    },
    {
        icon: IconWallet,
        iconDisabled: IconWalletStroke,
        text: "مالی",
        link: ["/panel/wallet"],
    },
    {
        icon: IconChartLineSolid,
        iconDisabled: IconChartLineSolid,
        text: "ورود به تاپ تریدر",
        link: ["https://app.avalpardakht.com"],
    },
    {
        icon: IconHeadset,
        iconDisabled: IconHeadsetStroke,
        text: "پشتیبانی",
        link: ["#support"],
        submenu: [
            {
                text: "تیکت‌ها",
                link: "/panel/ticket/list",
            },
            {
                text: "تیکت جدید",
                link: "/panel/ticket/new",
            },
            {
                text: "نظرها و انتقادات",
                link: "/panel/comments",
            },
        ],
    },
    {
        icon: IconHandshakeSimple,
        iconDisabled: IconHandshakeSimpleStroke,
        text: "همکاری در فروش",
        link: ["/panel/affiliate"],
    },
    // {
    //     icon: IconPaletteSolid,
    //     iconDisabled: IconPaletteStroke,
    //     text: "شخصی سازی",
    //     link: ["#appereance"],
    //     submenu: [
    //         {
    //             text: "تنظیمات قالب",
    //             link: "/panel/appearance/theme",
    //         },
    //         {
    //             text: "تنظیمات ویراست",
    //             link: "/panel/appereance/text",
    //         },
    //         {
    //             text: "راهنمایی",
    //             link: "/panel/appereance/help",
    //         },
    //     ],
    // },
];
