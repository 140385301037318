import classNames from "classnames";
import { HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, useId, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import InputMask, { Props as ReactInputMaskProps } from "react-input-mask";
import DatePicker, { CalendarProps, DatePickerProps as DatePickerComponentProps } from "react-multi-date-picker";

import { getFieldError } from "@/utils";

import IconCircleCheckDuotone from "../svg/circle-check-duotone";
import IconCircleExclamationStroke from "../svg/circle-exclamation-stroke";

interface InputBaseProps extends InputHTMLAttributes<HTMLInputElement> {
    type?: HTMLInputTypeAttribute;
    variant?: "stroke" | "fill";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    iconClassName?: string;
    containerClassName?: string;
    topTitle?: string | ReactNode;
    showConfirmedStatus?: boolean;
    addon?: React.ReactNode;
    required?: boolean;
    ltr?: boolean;
}

type MaskProps = {
    maskProps?: ReactInputMaskProps;
};

type FormProps = {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: RegisterOptions<any, string> | undefined;
};

type DatePickerProps = {
    datePicker: boolean;
    datePickerProps: Omit<CalendarProps, "onChange"> & DatePickerComponentProps;
};

type InputWithAddon = {
    icon?: never;
    addon: React.ReactNode;
};

type InputWithoutAddon = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: any;
    addon?: never;
};

type InputWithoutFormProps = MaskProps &
    DatePickerProps &
    InputBaseProps & {
        control?: never;
        name?: never;
        rules?: never;
    };

type InputWithoutFormWithoutDateProps = MaskProps &
    InputBaseProps & {
        datePicker?: never;
        datePickerProps?: never;
        name?: never;
        control?: never;
        rules?: never;
    };

type InputFormProps = MaskProps & DatePickerProps & FormProps & InputBaseProps;

type InputFormWithoutDateProps = MaskProps &
    FormProps &
    InputBaseProps & {
        datePicker?: never;
        datePickerProps?: never;
    };

type InputProps = (
    | InputWithoutFormProps
    | InputWithoutFormWithoutDateProps
    | InputFormProps
    | InputFormWithoutDateProps
) &
    (InputWithoutAddon | InputWithAddon);

const Input: React.FC<InputProps> = ({
    topTitle,
    variant = "stroke",
    name,
    rules,
    control,
    icon: Icon,
    addon,
    iconClassName,
    maskProps,
    datePicker,
    datePickerProps,
    disabled,
    className,
    containerClassName,
    showConfirmedStatus,
    ltr = false,
    required,
    ...rest
}) => {
    const id = useId();
    const [isFocused, setIsFocused] = useState(false);

    return control ? (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, formState: { errors } }) => {
                const error = getFieldError(field.name, errors);

                const classes = classNames(
                    {
                        "hidden": rest.type && rest.type == "hidden",
                        "rounded-[8px] border h-[40px] px-3 transition-colors flex justify-center items-center gap-3 text-text-main placeholder:text-text-paragraph": 1,
                        "bg-input-bg": variant === "fill",
                        "border-input-border focus-within:border-primary": !disabled && !error,
                        "border-error": error,
                        "hover:border-input-border-hover border-input-border": !isFocused && !error,
                    },
                    className,
                );

                return (
                    <div className={"relative space-y-3 " + containerClassName}>
                        {topTitle && (
                            <label
                                className={`whitespace-nowrap px-3 text-[0.85rem] text-text-main ${
                                    rest.type && rest.type == "hidden" ? "hidden" : ""
                                }`}
                                htmlFor={id}
                            >
                                {topTitle}
                                {required ? <span className="text-error"> *</span> : ""}
                            </label>
                        )}
                        <div className={classes} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
                            {error && <IconCircleExclamationStroke className="h-[25px] fill-error" />}
                            {!error && showConfirmedStatus && (
                                <IconCircleCheckDuotone className="h-[25px] fill-success" />
                            )}

                            {maskProps ? (
                                <InputMask
                                    {...rest}
                                    {...maskProps}
                                    {...field}
                                    ref={undefined}
                                    inputRef={field.ref}
                                    id={id}
                                    disabled={disabled}
                                    dir={ltr ? "ltr" : "rtl"}
                                    className={`${
                                        ltr ? "text-left placeholder:text-right" : ""
                                    } h-full w-full bg-transparent bg-clip-text text-[0.85rem] outline-none`}
                                    data-clarity-unmask="true"
                                >
                                    {(inputProps: any) => <input {...inputProps} />}
                                </InputMask>
                            ) : datePicker ? (
                                <DatePicker
                                    {...rest}
                                    portal
                                    id={id}
                                    value={field.value || ""}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    onChange={(date) => {
                                        if (date) field.onChange(date);
                                    }}
                                    {...datePickerProps}
                                    disabled={disabled}
                                    containerClassName="w-full"
                                    style={{ direction: ltr ? "ltr" : "rtl" }}
                                    inputClass={`${
                                        ltr ? "placeholder:text-right text-left" : ""
                                    } h-full w-full bg-clip-text bg-transparent text-[0.85rem] outline-none`}
                                    data-clarity-unmask="true"
                                />
                            ) : (
                                <input
                                    {...rest}
                                    {...field}
                                    id={id}
                                    disabled={disabled}
                                    dir={ltr ? "ltr" : "rtl"}
                                    className={`${
                                        ltr ? "text-left placeholder:text-right" : ""
                                    } h-full w-full bg-transparent bg-clip-text text-[0.85rem] outline-none placeholder:text-right`}
                                    data-clarity-unmask="true"
                                />
                            )}

                            {addon ?? (Icon && <Icon className={"h-[22px] fill-input-icon " + iconClassName} />)}
                        </div>

                        {error && (
                            <div className="px-3 text-[0.78rem] text-error">
                                <span>{error}</span>
                            </div>
                        )}
                    </div>
                );
            }}
        />
    ) : (
        <div className={"relative space-y-3 " + containerClassName}>
            {topTitle && (
                <label className="px-3 text-[0.85rem] text-text-main" htmlFor={id}>
                    {topTitle}
                </label>
            )}
            <div
                className={classNames(
                    {
                        "hidden": rest.type && rest.type == "hidden",
                        "flex h-[40px] items-center justify-center gap-3 rounded-[8px] border px-3 text-text-main transition-colors placeholder:text-text-paragraph": 1,
                        "bg-input-bg": variant === "fill",
                        "border-input-border focus-within:border-primary": !disabled,
                        "border-input-border hover:border-input-border-hover": !isFocused,
                    },
                    className,
                )}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            >
                {showConfirmedStatus && <IconCircleCheckDuotone className="h-[25px] fill-success" />}

                {maskProps ? (
                    <InputMask
                        {...rest}
                        {...maskProps}
                        id={id}
                        disabled={disabled}
                        className="h-full w-full bg-transparent bg-clip-text text-[1rem] outline-none"
                        data-clarity-unmask="true"
                    >
                        {(inputProps: any) => <input {...inputProps} />}
                    </InputMask>
                ) : datePicker ? (
                    <DatePicker
                        {...rest}
                        {...datePickerProps}
                        id={id}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(date: any) => {
                            if (date) rest.onChange && rest.onChange(date);
                        }}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        value={rest.value as any}
                        disabled={disabled}
                        containerClassName="w-full"
                        inputClass="h-full w-full bg-clip-text bg-transparent text-[1rem] outline-none"
                        data-clarity-unmask="true"
                    />
                ) : (
                    <input
                        {...rest}
                        id={id}
                        disabled={disabled}
                        className="h-full w-full bg-transparent bg-clip-text text-[1rem] outline-none placeholder:text-right"
                        data-clarity-unmask="true"
                    />
                )}

                {addon ?? (Icon && <Icon className={"h-[22px] fill-input-icon " + iconClassName} />)}
            </div>
        </div>
    );
};

export default Input;
