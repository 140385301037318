import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import ToastError from "@/components/error/ToastError";
import gate from "@/gate";
import { PaymentResponse } from "@/interfaces/dashboard/invoice/invoice";

const usePayWithGiftCard = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useMutation<PaymentResponse, Error, any>({
        mutationFn: gate.payWithGiftCard,
        onError: (error: Error) => {
            ToastError(error);
        },
        onSuccess: (data: { message: string }) => {
            toast.success(data.message);
        },
    });
};

export default usePayWithGiftCard;
