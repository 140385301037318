import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { DateObject } from "react-multi-date-picker";

import IconCirclePlus from "@/components/svg/circle-plus";
import IconIdCard from "@/components/svg/id-card";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import Modal from "@/components/ui/Modal";
import { banksList } from "@/data/Banks";
import useAddBankCard from "@/hooks/queries/useAddBankCard";
import useUser from "@/hooks/queries/useUser";
import useUploadHandler from "@/hooks/useUploadHandler";

import CreditCard from "./CreditCard";

type addCartSchema = {
    bank?: File;
    card_number: string;
    iban: string;
    account_number: string;
    birthday: DateObject;
    national_code: string;
};

const AddCreditCard: React.FC = () => {
    const queryClient = useQueryClient();

    const [show, setShow] = useState(false);
    const [serial, setSerial] = useState("");
    const [iban, setIban] = useState("");

    const { mutateAsync: addBankCard, isLoading } = useAddBankCard();
    const { data: user } = useUser();

    const autoGetBankData = user.data.verificationSettings.auto_get_bank_data;
    const isNationalCodeExist = user.data.user.national_code;
    const isBirthdayExist = user.data.user.birthday;

    const {
        setValue,
        reset,
        control,
        getValues,
        watch,
        handleSubmit,
        formState: { isValid, isSubmitted, errors },
    } = useForm<addCartSchema>({
        defaultValues: {
            bank: undefined,
            account_number: "",
            birthday: undefined,
            card_number: "",
            iban: "",
            national_code: "",
        },
    });

    const { renderTemplate, reset: resetUploadFile } = useUploadHandler({
        control,
        name: "bank",
        rules: { required: !autoGetBankData },
        error: !!errors.bank,
        title: "لطفا عکس کارت بانکی را آپلود کنید",
        setFormValue: setValue,
        className: "pt-8 pb-5",
    });

    const submitHandler: SubmitHandler<addCartSchema> = async (data) => {
        const card_number = data.card_number.replace(/\s/g, "");
        const bankDetails = banksList.find((bank) => card_number.includes(String(bank.code)));

        const response = await addBankCard({
            card_number,
            account_number: data.account_number ? data.account_number.replace(/\s/g, "") : null,
            iban: data.iban ? data.iban.replace(/\s/g, "") : null,
            national_code: data.national_code ? data.national_code.replace(/\s/g, "") : undefined,
            birthday: data.birthday?.toString(),
            name: bankDetails ? bankDetails.en_alias : "ناشناس",
            attachments: data.bank ?? undefined,
        });

        if (response && response?.message) {
            toast.success(response.message);
        }

        onModalClose();
    };

    useEffect(() => {
        const subscription = watch(() => {
            let serialNumber = getValues("card_number") ?? "";
            serialNumber = serialNumber.replace(/\s/g, "");
            if (serialNumber.length < 17) setSerial(serialNumber);

            let ibanNumber = getValues("iban") ?? "";
            ibanNumber = ibanNumber.replace(/(IR|ir|\s)/g, "");
            setIban(ibanNumber);
        });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const onModalClose = () => {
        reset({
            bank: undefined,
            account_number: "",
            birthday: undefined,
            card_number: "",
            iban: "",
            national_code: "",
        });
        setShow(!show);
        resetUploadFile();
        setIban("");
        setSerial("");
        queryClient.invalidateQueries(["bank-cards"]);
        queryClient.invalidateQueries(["userData"]);
    };

    return (
        <>
            <button
                onClick={() => setShow(true)}
                className="relative flex aspect-[16/9.5] w-full max-w-[420px] flex-col items-center justify-center gap-4 rounded-[25px] border-2 border-dashed border-primary bg-secondary-400 dark:border-secondary-200"
            >
                <IconCirclePlus className="h-[40px] fill-primary" />
                <span className="text-[1.28rem] text-text-main">افزودن کارت بانکی</span>
            </button>
            <Modal onClose={onModalClose} show={show} title="افزودن کارت بانکی" className="max-w-[500px]">
                <div className="flex w-full items-center justify-center">
                    <form
                        className="flex w-full flex-col items-center justify-start gap-10 space-y-8"
                        onSubmit={handleSubmit(submitHandler)}
                    >
                        <div className="mt-2 flex w-full items-center justify-center min-[460px]:max-w-[380px]">
                            {autoGetBankData && (
                                <CreditCard name="اول پرداخت" serial={serial} iban={iban} status="CONFIRMED" />
                            )}
                            {!autoGetBankData && renderTemplate}
                        </div>
                        <div className="!m-0 flex w-full flex-col gap-6">
                            <div className="flex w-full items-start justify-center gap-4 max-[400px]:flex-col">
                                <Input
                                    control={control}
                                    ltr
                                    autoFocus
                                    containerClassName="w-full"
                                    variant="fill"
                                    topTitle="شماره کارت"
                                    name="card_number"
                                    inputMode="numeric"
                                    autoComplete="off"
                                    maskProps={{
                                        mask: "9999 9999 9999 9999",
                                        maskChar: "",
                                    }}
                                    rules={{
                                        required: "شماره کارت الزامی است",
                                        pattern: {
                                            value: /^.{19}$/, // Three space characters added to 16 digits card number
                                            message: "شماره کارت باید 16 رقم باشد",
                                        },
                                    }}
                                />
                                {!autoGetBankData && (
                                    <Input
                                        control={control}
                                        name="account_number"
                                        ltr
                                        containerClassName="w-full"
                                        variant="fill"
                                        topTitle="شماره حساب"
                                        inputMode="numeric"
                                        autoComplete="off"
                                        maskProps={{
                                            mask: "999999999999999999",
                                            maskChar: "",
                                        }}
                                        rules={{
                                            required: "شماره حساب الزامی است",
                                            pattern: {
                                                value: /^.{1,18}$/,
                                                message: "شماره حساب نامعتبر است",
                                            },
                                        }}
                                    />
                                )}
                            </div>
                            {!autoGetBankData && (
                                <Input
                                    name="iban"
                                    control={control}
                                    ltr
                                    topTitle="شماره شبا"
                                    className="w-full"
                                    variant="fill"
                                    maskProps={{
                                        mask: "IR 99 9999 9999 9999 9999 9999 99",
                                        maskChar: "",
                                        alwaysShowMask: true,
                                    }}
                                    autoComplete="off"
                                    rules={{
                                        required: "شماره شبا الزامی است",
                                        pattern: { value: /^.{33}$/, message: "شماره شبا باید 24 رقم باشد" },
                                    }}
                                />
                            )}
                            {autoGetBankData && (!isBirthdayExist || !isNationalCodeExist) && (
                                <div className="flex w-full items-start justify-center gap-4 max-[400px]:flex-col">
                                    {!isBirthdayExist && (
                                        <Input
                                            ltr
                                            name="birthday"
                                            control={control}
                                            containerClassName="w-full"
                                            variant="fill"
                                            topTitle="تاریخ تولد"
                                            datePicker
                                            datePickerProps={{
                                                currentDate: new DateObject({ calendar: persian }).subtract(18, "year"),
                                                format: "YYYY/MM/DD",
                                                calendar: persian,
                                                locale: persian_fa,
                                                maxDate: new DateObject({ calendar: persian }).subtract(18, "year"),
                                            }}
                                            rules={{
                                                required: "تاریخ تولد الزامی است",
                                            }}
                                        />
                                    )}
                                    {!isNationalCodeExist && (
                                        <Input
                                            ltr
                                            name="national_code"
                                            control={control}
                                            containerClassName="w-full"
                                            variant="fill"
                                            icon={IconIdCard}
                                            topTitle="کد ملی"
                                            maskProps={{
                                                mask: "9999999999",
                                                maskChar: "",
                                            }}
                                            inputMode="numeric"
                                            rules={{
                                                required: "کد ملی الزامی است",
                                                pattern: {
                                                    value: /^.{10}$/,
                                                    message: "کد ملی باید 10 رقم باشد",
                                                },
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            <Button
                                className="mt-3 w-full"
                                disabled={isSubmitted || !isValid || isLoading}
                                disabledTooltip="دکمه غیرفعال است"
                                loading={isLoading}
                            >
                                ثبت کارت
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
};

export default AddCreditCard;
