import { useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import GiftCard from "@/components/invoice/GiftCard";
import Button from "@/components/ui/Button";
import Input from "@/components/ui/Input";
import Select from "@/components/ui/Select";
import Textarea from "@/components/ui/Textarea";
import useInvoice from "@/hooks/queries/useInvoice";
import usePayWithGiftCard from "@/hooks/queries/usePayWithGiftCard";
import { toLatin } from "@/utils";

type DraftPaymentSchema = {
    cardNumber: string;
    fullName: string;
    cvv: string;
    year: {
        value: string;
        label: string;
    };
    month: {
        value: string;
        label: string;
    };
    description?: string;
};

const GiftCardPayment = () => {
    const queryClient = useQueryClient();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const { data: invoice } = useInvoice(Number(params.invoiceId ?? searchParams.get("id")));
    const { mutateAsync: payWithGiftCard, isLoading } = usePayWithGiftCard();

    const status = invoice?.transaction.status;
    const isPaymentDisabled = status !== "INIT";

    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<DraftPaymentSchema>({
        defaultValues: {
            cardNumber: "",
            fullName: "",
            cvv: "",
        },
    });

    const [fullName, cardNumber, cvv, year, month] = useWatch({
        control,
        name: ["fullName", "cardNumber", "cvv", "year", "month"],
        defaultValue: {
            cardNumber: "",
            cvv: "",
            fullName: "اول پرداخت",
            month: {
                value: "",
                label: "",
            },
            year: {
                value: "",
                label: "",
            },
        },
    });

    const onSubmit: SubmitHandler<DraftPaymentSchema> = async (data) => {
        try {
            const response = await payWithGiftCard({
                id: Number(params.invoiceId ?? searchParams.get("id")),
                data: {
                    meta: {
                        bank_pay_description: data.description,
                        site_gateway_full_name: data.fullName,
                        site_gateway_cart_number: data.cardNumber.replaceAll(" ", ""),
                        site_gateway_cvv: data.cvv,
                        site_gateway_year: data.year.value,
                        site_gateway_month: data.month.value,
                    },
                },
            });

            if (response.error === false) {
                await queryClient.refetchQueries(["invoice", Number(params.invoiceId ?? searchParams.get("id"))]);

                if (invoice?.transaction.type == "order") {
                    navigate("/panel/order/list/" + response.redirect_url.split("/").pop());
                } else {
                    navigate("/");
                }
            }
        } catch (error) {
            //
        }
    };

    return (
        <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex w-full flex-col items-center justify-center gap-4">
                <div className="mt-2 flex w-full items-center justify-center min-[460px]:max-w-[380px]">
                    <GiftCard
                        fullName={fullName ? fullName : "اول پرداخت"}
                        cardNumber={cardNumber ?? ""}
                        cvv={cvv ? cvv.toString().padEnd(4, "0") : "0000"}
                        year={year ? year.value.toString().padEnd(4, "0") : "0000"}
                        month={month ? month.value.toString().padStart(2, "0") : "00"}
                    />
                </div>
                <div className="!m-0 flex w-full flex-col gap-6">
                    <Input
                        control={control}
                        ltr
                        required
                        containerClassName="w-full"
                        variant="fill"
                        topTitle="شماره کارت"
                        name="cardNumber"
                        inputMode="numeric"
                        autoComplete="off"
                        maskProps={{
                            mask: "9999 9999 9999 9999",
                            maskChar: "",
                        }}
                        rules={{
                            required: "شماره کارت الزامی است",
                            pattern: {
                                value: /^.{19}$/, // Three space characters added to 16 digits card number
                                message: "شماره کارت باید 16 رقم باشد",
                            },
                        }}
                    />
                    <div className="flex w-full items-start justify-center gap-4 max-[400px]:flex-col">
                        <Input
                            control={control}
                            name="fullName"
                            required
                            containerClassName="w-full"
                            variant="fill"
                            topTitle="نام و نام خانوادگی"
                            autoComplete="off"
                            rules={{
                                required: "نام و نام خانوادگی الزامی است",
                            }}
                        />
                        <Input
                            control={control}
                            ltr
                            required
                            containerClassName="w-full"
                            variant="fill"
                            topTitle="رمز روی کارت (cvv)"
                            name="cvv"
                            inputMode="numeric"
                            autoComplete="off"
                            maskProps={{
                                mask: "9999",
                                maskChar: "",
                            }}
                            rules={{
                                required: "رمز روی کارت (cvv) الزامی است",
                                pattern: {
                                    value: /^.{4}$/, // Three space characters added to 16 digits card number
                                    message: "رمز روی کارت (cvv) باید 4 رقم باشد",
                                },
                            }}
                        />
                    </div>

                    <div className="flex w-full items-start justify-center gap-4 max-[400px]:flex-col">
                        <Select
                            control={control}
                            topTitle="ماه"
                            required
                            name="month"
                            variant="fill"
                            className="w-full"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب ماه الزامی است",
                            }}
                            options={Array.from({ length: 12 }, (_, index) => {
                                const months = [
                                    "01 - فروردین",
                                    "02 - اردیبهشت",
                                    "03 - خرداد",
                                    "04 - تیر",
                                    "05 - مرداد",
                                    "06 - شهریور",
                                    "07 - مهر",
                                    "08 - آبان",
                                    "09 - آذر",
                                    "10 - دی",
                                    "11 - بهمن",
                                    "12 - اسفند",
                                ];

                                return {
                                    value: (index + 1).toString().padStart(2, "0"),
                                    label: months[index],
                                };
                            })}
                        />

                        <Select
                            control={control}
                            topTitle="سال"
                            required
                            name="year"
                            variant="fill"
                            className="w-full"
                            placeholder="انتخاب کنید"
                            rules={{
                                required: "انتخاب سال الزامی است",
                            }}
                            options={Array.from({ length: 101 }, (_, index) => {
                                const currentYear = Number(
                                    toLatin(new Date().toLocaleDateString("fa-IR", { year: "numeric" })),
                                );

                                return {
                                    value: currentYear + index,
                                    label: currentYear + index,
                                };
                            })}
                        />
                    </div>
                </div>
            </div>

            <Textarea
                disabled={isPaymentDisabled}
                control={control}
                label="توضیحات"
                name="description"
                containerClass="!mt-5"
            />

            <Button
                className="!mt-8 w-full"
                type="submit"
                loading={isLoading}
                disabled={isLoading || !isValid || isPaymentDisabled}
                disabledTooltip="دکمه غیرفعال است"
            >
                ثبت
            </Button>
        </form>
    );
};

export default GiftCardPayment;
