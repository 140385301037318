import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import BuyFormPlaceholder from "@/components/placeholder/order/BuyFormPlaceholder";
import ChargeFormPlaceholder from "@/components/placeholder/order/ChargeFormPlaceholder";
import GiftFormPlaceholder from "@/components/placeholder/order/GiftFormPlacehoder";
import OpeningFormPlaceholder from "@/components/placeholder/order/OpeningFormPlaceholder";
import PerfectVoucherPlaceholder from "@/components/placeholder/order/PerfectVoucherPlaceholder";
import SafePalPlaceholder from "@/components/placeholder/order/SafePalPlaceholder";
import WebmoneyPlaceholder from "@/components/placeholder/order/WebmoneyPlaceholder";
import useGetOrderServiceInstruction from "@/hooks/queries/useGetOrderServiceInstruction";
import useLocalStorage from "@/hooks/useLocalStorage";
import { CalculationRequestType, ServicesListType, ServiceType } from "@/interfaces/dashboard/order/order";
import { isEmpty } from "@/utils";

import BuyForm from "./BuyForm";
import ChargeForm from "./ChargeForm";
import ChargeWebmoneyForm from "./ChargeWebmoneyForm";
import CryptoChargeForm from "./CryptoChargeForm";
import GiftForm from "./GiftForm";
import OpeningForm from "./OpeningForm";
import PerfectVoucherForm from "./PerfectVoucherForm";
import SafePalForm from "./SafePalForm";
import SwiftForm from "./SwiftForm";

type OrderFormProps = {
    onCalculation: (data: CalculationRequestType) => void;
    onFormSubmit: (data: object, serviceId: number) => void;
    onDataAvailable: (service: ServiceType) => void;
};

const OrderFormFile: React.FC<OrderFormProps> = ({ onCalculation, onFormSubmit, onDataAvailable }) => {
    const { serviceType } = useParams();
    const [allServices] = useLocalStorage<ServicesListType | undefined>("all_services", undefined);

    const { data } = useGetOrderServiceInstruction(serviceType);

    useEffect(() => {
        if (data) {
            onDataAvailable(data);
        }
    }, [data, onDataAvailable]);

    const orderForm = useMemo(() => {
        if (!data && !isEmpty(allServices)) {
            const filteredServices = allServices?.services?.filter((service) => {
                return service.slug == serviceType;
            });

            if (!isEmpty(filteredServices) && filteredServices?.length) {
                const service = filteredServices[0];
                const formFile = service?.form_file;

                if (formFile?.endsWith("gift.buy")) {
                    return <GiftFormPlaceholder />;
                } else if (formFile?.endsWith("webmoney.charge")) {
                    return <WebmoneyPlaceholder />;
                } else if (formFile?.endsWith("perfectmoney.voucher") || formFile?.endsWith("utopia.voucher")) {
                    return <PerfectVoucherPlaceholder />;
                } else if (formFile?.endsWith("buy")) {
                    return <BuyFormPlaceholder />;
                } else if (formFile?.endsWith("opening")) {
                    return <OpeningFormPlaceholder />;
                } else if (formFile?.endsWith("charge")) {
                    return <ChargeFormPlaceholder />;
                } else if (formFile?.endsWith("vip.trezor")) {
                    return <SafePalPlaceholder />;
                } else if (formFile?.endsWith("draft.personal-swift")) {
                    return <SafePalPlaceholder />;
                }
            }
        }

        if (data) {
            const formFile = data.form_file;

            if (formFile.endsWith("gift.buy")) {
                return <GiftForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />;
            } else if (formFile.endsWith("webmoney.charge")) {
                return (
                    <ChargeWebmoneyForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />
                );
            } else if (formFile.endsWith("perfectmoney.voucher") || formFile.endsWith("utopia.voucher")) {
                return (
                    <PerfectVoucherForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />
                );
            } else if (formFile.endsWith("buy")) {
                return <BuyForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />;
            } else if (formFile.endsWith("opening")) {
                return <OpeningForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />;
            } else if (formFile.endsWith("charge")) {
                if (data.is_crypto) {
                    return (
                        <CryptoChargeForm
                            onCalculation={onCalculation}
                            onFormSubmit={onFormSubmit}
                            instructions={data}
                        />
                    );
                } else {
                    return <ChargeForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />;
                }
            } else if (data.form_file.endsWith("vip.trezor")) {
                return <SafePalForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />;
            } else if (data.form_file.endsWith("draft.personal-swift")) {
                return <SwiftForm onCalculation={onCalculation} onFormSubmit={onFormSubmit} instructions={data} />;
            }
        }
    }, [allServices, data, onCalculation, onFormSubmit, serviceType]);

    return orderForm || <></>;
};

export default OrderFormFile;
