import { useEffect, useMemo, useState } from "react";

import CreditCard from "@/components/profile/credit-cards/CreditCard";
import AlertBox from "@/components/ui/AlertBox";
import Title from "@/components/ui/Title";
import { BRIDGE_SUPPORT_BANKS, POL_TRANSFER_LIMIT } from "@/constants/common";
import { banksList } from "@/data/Banks";
import { DraftCard, GetTransactionResponse } from "@/interfaces/dashboard/invoice/invoice";
import { formatNumber } from "@/utils";

type ShowDraftBankCardsProps = {
    draftCard: GetTransactionResponse["draft_card"];
    amount?: number;
};

const ShowDraftBankCards = ({ draftCard, amount }: ShowDraftBankCardsProps) => {
    const [selectBankCategory, setSelectedBankCategory] = useState<string | null>(null);

    useEffect(() => {
        if (draftCard) {
            setSelectedBankCategory(draftCard[0].name);
        }
    }, [draftCard]);

    const renderCreditCategory = useMemo(() => {
        const filteredBanks: DraftCard[] = [];

        draftCard.forEach((card) => {
            if (!filteredBanks.find((bank) => bank.name === card.name)) {
                filteredBanks.push(card);
            }
        });

        return filteredBanks.map((card) => {
            const bankData = banksList.find((bank) => card.card_number.includes(String(bank.code)));

            return (
                <button
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={bankData?.name}
                    data-tooltip-place="bottom"
                    type="button"
                    key={card.id}
                    onClick={() => setSelectedBankCategory(card.name)}
                    className={`flex w-[80px] flex-col items-center justify-center rounded-[10px] border-2 py-3 transition-colors xs:w-[100px] ${
                        selectBankCategory === card.name ? "border-primary bg-primary/10" : "border-secondary-100"
                    }`}
                >
                    <img
                        src={"/images/banks/" + bankData?.logo}
                        alt={card.name}
                        className="aspect-square w-[40px] xs:w-[50px] sm:w-[60px]"
                    />
                </button>
            );
        });
    }, [draftCard, selectBankCategory]);

    const renderBanks = useMemo(() => {
        return draftCard
            .filter((bank) => bank.name === selectBankCategory)
            .map((bankCard) => {
                return (
                    <CreditCard
                        account={bankCard.account_number}
                        key={bankCard.id}
                        serial={bankCard.card_number}
                        iban={bankCard.iban}
                        status="CONFIRMED"
                        name="شرکت پردازش کاران تجارت یاشار"
                    />
                );
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectBankCategory]);

    return (
        <div className="px-2">
            <Title title="کارت‌های حواله" containerClasses="-mx-5" />
            {amount && amount <= POL_TRANSFER_LIMIT && (
                <AlertBox
                    description={
                        <>
                            تا مبلغ 10,000,000 تومان می‌توانید به یکی از حساب‌های زیر کارت به کارت کرده و در صورتی که
                            بانک شما از سامانه پُل پشتیبانی می‌کند، تا مبلغ &nbsp;
                            {formatNumber(POL_TRANSFER_LIMIT)} تومان را می‌توانید بصورت آنی از طریق{" "}
                            <button
                                data-tooltip-id="main-tooltip"
                                data-tooltip-html={`
                                        بانک‌هایی که سامانه پل را پشتیبانی می‌کنند: <br /><br />
                                        <div class="grid grid-cols-2 gap-3">
                                            <div>${BRIDGE_SUPPORT_BANKS.join("</div> <div>")}</div>
                                        </div>
                                `}
                                data-tooltip-place="bottom"
                                type="button"
                                className="text-primary hover:text-primary"
                            >
                                سامانه پُل
                            </button>{" "}
                            (با استفاده از شماره شبا) به حساب‌های مجموعه انتقال دهید. شماره حساب برای مبلغ بیشتر را از
                            طریق تیکت یا تلفن‌های مجموعه هماهنگ کنید.
                            <br />
                            <span className="text-error">**</span>
                            حتما در قسمت بابت عبارت <span className="text-error">
                                تسویه بدهی یا تادیه قرض و دیون
                            </span>{" "}
                            یا <span className="text-error">خرید خدمات</span> را درج نمایید در غیر اینصورت مبلغ واریزی
                            عودت داده خواهد شد
                            <span className="text-error">**</span>
                        </>
                    }
                    type="info"
                />
            )}
            {draftCard.length > 1 && (
                <div className="flex w-full items-center justify-center">
                    <div className="mt-6 flex max-w-[600px] flex-wrap justify-evenly gap-4 xs:gap-8">
                        {renderCreditCategory}
                    </div>
                </div>
            )}
            <div className="flex w-full flex-col items-center justify-center gap-6 pb-8 pt-12">{renderBanks}</div>
        </div>
    );
};

export default ShowDraftBankCards;
